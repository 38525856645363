import { defineStore } from 'pinia';
import { ref, inject, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Form  from '../components/form/Form.vue';
import Status from '../components/status/Status.vue';
import axiosWrapper from "../utils/axiosWrapper";

export const useGeneralStore = defineStore('general', () => {

    const i18n = useI18n();

    const steps = [ Form, Status ];
    const activeStep = ref(0);
    const emailAddress = 'support@evisa.express';
    const languages = inject('languages');
    const isLoading = ref(false);
    const formStatus = ref(null);
    const isTestMode = ref(null);
    const sentAt = ref(null);
    const orderHash = ref(null);
    const surchargeHash = ref(null);

    const processingStatuses= [1, 6, 11, 13];
    const notUnpaidStatuses = [4, 9, 14, 'test_mode'];

    const appDate = computed(() => {
        if(sentAt.value) {
            const date = sentAt.value.split(' ')[0]
            const year = date.split('-')[0];
            const month = new Date(1920, (date.split('-')[1] - 1), 15).toLocaleDateString('en', { month: 'long' });
            const day = date.split('-')[2];

            return day + ' ' + getTranslationComputed('form.' + month).value + ' ' + year;
        }
    })

    const languageInputData = {
        name: 'languageSelect',
        label: getTranslationComputed('languageSwitcher.selectLabel'),
        options: languages.map(lang => {
            return {
                value: lang,
                label: getTranslationComputed('languageSwitcher.' + lang)
            }
        }),
    }

    const form = [
        {
            name: 'idStr',
            type: 'TextInput',
            label: getTranslationComputed('form.idStr'),
            placeholder: getTranslationComputed('form.idStrPlaceholder'),
            errorMessage: getTranslationComputed('form.idStrErrorMessage')
        },
        {
            name: 'dateOfBirth',
            type: 'DateSelect',
            label: {
                day: getTranslationComputed('form.dayOfBirth'),
                month: getTranslationComputed('form.monthOfBirth'),
                year: getTranslationComputed('form.yearOfBirth')
            },
            placeholder: {
                day: getTranslationComputed('form.dayOfBirthPlaceholder'),
                month: getTranslationComputed('form.monthOfBirthPlaceholder'),
                year: getTranslationComputed('form.yearOfBirthPlaceholder')
            },
            errorMessage: {
                day: getTranslationComputed('form.dayOfBirthErrorMessage'),
                month: getTranslationComputed('form.monthOfBirthErrorMessage'),
                year: getTranslationComputed('form.yearOfBirthErrorMessage'),
            }
        },
        {
            name: 'surname',
            type: 'TextInput',
            label: getTranslationComputed('form.surname'),
            placeholder: getTranslationComputed('form.surnamePlaceholder'),
            errorMessage: getTranslationComputed('form.surnameErrorMessage')
        }
    ];

    const fields = ref(getFields());

    function getFields() {
        let fields = {};

        form.forEach((field) => {
            if (field.name === 'dateOfBirth') {
                fields[field.name] = {
                    value: {
                        day: 0,
                        month: 0,
                        year: 0
                    },
                    touched: false,
                    valid: false
                }
            } else {
                fields[field.name] = {
                    value: '',
                    touched: false,
                    valid: false
                }
            }
        });
    
        return fields;
    }

    function getTranslationComputed(key) {
        return computed(() => {
            return i18n.t(key);
        });
    }

    function getTranslationLabel(index) {
        const month = new Date(1920, index, 15).toLocaleDateString('en', { month: 'long' });

        return getTranslationComputed('form.' + month).value
    }

    function nextStep() {
        activeStep.value++;
    }

    function previousStep() {
        activeStep.value--;
    }

    function markErrorFields(response)
    {
        for (const [field, error] of Object.entries(JSON.parse(response.detail))) {
            let fieldName = field.replace(/[\[|\]]/g, '');
            for (let a = 0, elements = form.length; a < elements; a++) {
                if (form[a].name === fieldName) {
                    form[a].errorMessage = error;
                    break;
                }
            }
            fields['_rawValue'][fieldName]['valid'] = false;
        }
    }

    async function getApplicationData(formData) {
        isLoading.value = true;

        const response = await getStatus(formData);

        switch (response?.status) {
            case 200:
                return await computeResponse(response);
            case 401:
            case 403:
                isLoading.value = false;
                formStatus.value = 'forbidden';
                return false;
            case 400:
                isLoading.value = false;
                markErrorFields(response.data);
                return false;
            case 404:
                isLoading.value = false;
                formStatus.value = 'not_found';
                return false;
            default:
                console.error('Network response was not ok.');
                isLoading.value = false;
                return false;
        }
    }

    const checkStatus = (data, statusesArray) => {
        return statusesArray.find(statusNumber =>
          data.status === statusNumber
        )
    }

    async function computeResponse(response) {
        const data = await response.data;

        if (data.paymentStatus) {
            if (data.isTestMode) {
                isTestMode.value = true;
            }
            if (checkStatus(data, processingStatuses)) {
                formStatus.value = 'status-1';
            } else if (data.status === 12) {
                formStatus.value = 'underpayment';
            } else {
                formStatus.value = data.isTestMode ? data.status :'status-' + data.status;
            }
        } else {
            if (data.isTestMode) {
                isTestMode.value = true;
            }
            if (data.status === 12) {
                formStatus.value = 'underpayment';
            } else if (!checkStatus(data, notUnpaidStatuses)) {
                formStatus.value = 'unpaid';
            } else {
                formStatus.value = data.isTestMode ? data.status :'status-' + data.status;
            }
        }

        sentAt.value = data.sentAt ?? '';
        orderHash.value = data.orderHash ?? '';
        surchargeHash.value = data.surchargeHash ?? '';

        isLoading.value = false;
        return true;
    }

    async function getStatus(data) {
        let locale = location.href.substring(location.href.length-2);
        return axiosWrapper({
            method: 'POST',
            headers: {
                'Content-language': languages.includes(locale) ? locale : 'en'
            },
            url: process.env.VITE_ENDPOINT_INTERNAL_APPLICATION_STATUS,
            data
          }
        )
    }

    return {
        nextStep,
        previousStep,
        getApplicationData,
        getTranslationLabel,
        steps,
        activeStep,
        emailAddress,
        languageInputData,
        form,
        fields,
        isLoading,
        formStatus,
        appDate,
        orderHash,
        surchargeHash,
        isTestMode
    }
});